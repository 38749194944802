import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  @Output() onClickMenuItem:EventEmitter<any> = new EventEmitter<any>();
 public parentMenu:Array<any>;

  constructor( public menuService:MenuService, public router:Router) {

  }

  ngOnInit() {


    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    console.log(this.parentMenu);
    console.log(this.menuParentId);
  }

  ngAfterViewInit(){

  }

  onClick(menuId){
    /*
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
    this.onClickMenuItem.emit(menuId);
    */
  }

}
