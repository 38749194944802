import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VerticalMenuComponent } from '../shared/component/menu/vertical-menu/vertical-menu.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    VerticalMenuComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    VerticalMenuComponent
  ],
  entryComponents: [VerticalMenuComponent]
})
export class ComponentsModule { }
