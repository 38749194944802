import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private _toastrService:ToastrService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       // const currentUser = this.authenticationService.currentUserValue;
       const token: string = localStorage.getItem('token');
        if (this.authenticationService.isLoggedIn()==true ) {
            // logged in so return true
            return true;
        }
        try {
            if(this.authenticationService.isLoggedIn()){
                console.log("No puedes acceder a este modulo..")
                this._toastrService.warning("No tienes acceso a este modulo")
            }
        } catch (error) {

        }

        this.authenticationService.logout();
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
