import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Menu } from './menu.model';
import { Observable,throwError } from 'rxjs';
import { map, startWith,takeWhile, delay ,catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
const state = {
  menus: JSON.parse(localStorage['gdd_menus'] || '[]'),

}
@Injectable()
export class MenuService {

public Menus;
private localUrl: string;
  constructor(private location:Location,
              private http: HttpClient,
              private router:Router){

                this.localUrl = environment.ApiBaseUrl;
              }

// menus
private get menus(): Observable<Menu[]> {
  //this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
  this.Menus = this.http.get<any[]>(this.localUrl+'/api/menus/getAllActive').pipe(map(data => data));
  this.Menus.subscribe(next => { localStorage['gdd_menus'] = JSON.stringify(next) });
  return this.Menus = this.Menus.pipe(startWith(JSON.parse(localStorage['gdd_menus'] || '[]')));
}
// Get Products
public get getMenus(): Observable<Menu[]> {
  return this.menus;
}
  public expandActiveSubMenu(menu:Array<Menu>){
      let url = this.location.path();
      let routerLink = url; // url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
        let menuItem = activeMenuItem[0];
        while (menuItem.parentId != 0){
          let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
          menuItem = parentMenuItem;
          this.toggleMenuItem(menuItem.id);
        }
      }
  }

  public toggleMenuItem(menuId){
    let menuItem = document.getElementById('menu-item-'+menuId);
    let subMenu = document.getElementById('sub-menu-'+menuId);
    if(subMenu){
      if(subMenu.classList.contains('show')){
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else{
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu:Array<Menu>, menuId){
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if(currentMenuItem.parentId == 0 && !currentMenuItem.target){
      menu.forEach(item => {
        if(item.id != menuId){
          let subMenu = document.getElementById('sub-menu-'+item.id);
          let menuItem = document.getElementById('menu-item-'+item.id);
          if(subMenu){
            if(subMenu.classList.contains('show')){
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }


}
