// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  GoogleClientId:'509236613968-m0hee39cg1suk5ctbu0qksn49bn1uot9.apps.googleusercontent.com',
  //GoogleClientId: '509236613968-vcposh753288jfgtb09gnjlrj8iurvkm.apps.googleusercontent.com',

  ApiBaseUrl:"http://34.67.88.4:5001/",
  ApiLoginBaseUrl:"http://34.67.88.4:5001",
  //ApiBaseUrl:"http://localhost:60781/",
  //ApiLoginBaseUrl:"http://localhost:60781",
};
