import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

//


//Servicios
import {AuthorizationService} from './services/authorization.service';
import {AuthenticationService} from './services/authentication.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ExcelService } from './services/excel.service';
import { AuthGuard } from './services/auth-guard.service';
import { MenuService } from './shared/component/menu/menu.service';
/*google social*/
import { SocialLoginModule,SocialAuthServiceConfig} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { environment } from '../environments/environment';

//loadin
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader-interceptor.service';
import { MyLoaderComponent } from './components/loader/my-loader.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgbModule,
    SocialLoginModule,
    ToastrModule.forRoot()
  ],
  entryComponents:[

   // MaterialFileUploadComponent
  ],
  declarations: [
    AppComponent,
    MyLoaderComponent,
    AdminLayoutComponent,

  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GoogleClientId
              //'509236613968-b75rm7vcu1huufip74pkskui3q7nvpu9.apps.googleusercontent.com'
            ),
          },

        ],
      } as SocialAuthServiceConfig,
    },
    AuthorizationService,
    AuthenticationService,
    ExcelService,
    LoaderService,
    MenuService,
    //{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
