import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthorizationService
{
    private localUrl: string;

  constructor (protected http: HttpClient) {
    this.localUrl = environment.ApiBaseUrl;
  }

  post(urlrequest: string, data?: any): Observable<any> {
    const url = this.localUrl + '/' + urlrequest;
    return this.http.request('post', url, {body: data, observe: 'response'})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((res) => {
          return this.handleResponseError(res, '');
        }),
      );
  }
  get(urlrequest: string): Observable<any> {
    const url = this.localUrl + '/' + urlrequest;
    //return this.http.get<any>( url, { observe: 'response' });
    return this.http.request('get', url, {observe: 'response'})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((res) => {
          return this.handleResponseError(res, '');
        }),
      );
  }
  put(urlrequest: string, data?: any): Observable<any> {
    const url = this.localUrl + '/' + urlrequest;
    return this.http.request('put', url, {body: data, observe: 'response'})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((res) => {
          return this.handleResponseError(res, '');
        }),
      );
  }
  delete(urlrequest: string): Observable<any> {
    const url = this.localUrl + '/' + urlrequest;
    return this.http.request('delete', url, {observe: 'response'})
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((res) => {
          return this.handleResponseError(res, '');
        }),
      );
  }

  protected handleResponseError(res: any, module: string): Observable<any> {
    let errors = [];
    if (res instanceof HttpErrorResponse) {
        console.log(res);
      //errors = this.getOption('errors.getter')(module, res, this.options);
    }
    else {
      errors.push('Something went wrong.');
    }
    return observableOf(
      res);
    /*return observableOf(
      res
      /*new NbAuthResult(
        false,
        res,
        this.getOption(`${module}.redirect.failure`),
        errors,
      ));*/
  }

}
