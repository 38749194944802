import { Subscriber } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../shared/component/menu/menu.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' },
    { path: '/gdd/categoriaslist', title: 'Categorias',  icon:'education_atom', class: '' },
    { path: '/gdd/itemnoelizadoList', title: 'Conductas',  icon:'education_atom', class: '' },
    { path: '/gdd/observacionesList', title: 'Observaciones',  icon:'education_atom', class: '' },
    { path: '/empleados/upload', title: 'Cargar Empleados',  icon:'education_atom', class: '' },
    /*{ path: '/icons', title: 'Icons',  icon:'education_atom', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_map-big', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'ui-1_bell-53', class: '' },

    { path: '/user-profile', title: 'User Profile',  icon:'users_single-02', class: '' },
    { path: '/table-list', title: 'Table List',  icon:'design_bullet-list-67', class: '' },
    { path: '/typography', title: 'Typography',  icon:'text_caps-small', class: '' },
    { path: '/upgrade', title: 'Upgrade to PRO',  icon:'objects_spaceship', class: 'active active-pro' }*/

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  menuItemsv2: any[];
  userData:any;
  constructor(
    private _authenticationService:AuthenticationService,
    private _menuService:MenuService,
    private router: Router
    ) {

      try {

        this._menuService.getMenus.subscribe(menu =>
          this.menuItemsv2 = menu
          //console.log(this.menuItemsv2);

          );
      } catch (error) {

      }

    }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.userData=this._authenticationService.getUserInfo();

  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };

  logout(){

    this._authenticationService.logout();
    this.router.navigate(['/']);
    //this.router.navigate(['/']);
  }

}
