import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable,of } from 'rxjs';
import { map,tap,catchError, mapTo  } from 'rxjs/operators';
import { Tokens } from './tokens';
import { environment } from '../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
//import { ConsoleReporter } from 'jasmine';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly JWT_TOKEN = 'GDD_TOKEN';
  private readonly REFRESH_TOKEN = 'GDD_REFRESH_TOKEN';
  private readonly ACCESS_MODULE = 'GDD_ACCESS_MODULE';
  private loggedUser: string;
  errors: string[];
    constructor(private http: HttpClient,private _toastrService:ToastrService) {
     //   this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
       // this.currentUser = this.currentUserSubject.asObservable();
    }



    login(data) {
      this.errors = [];

        return this.http.post<any>(`${environment.ApiBaseUrl}/api/account/login`,  data)
        .pipe(
            tap(tokens => this.doLoginUser(data.email, tokens)),
            mapTo(true),
            catchError(error => {
             this.SendMessage(error);
             // console.log(error);
             // alert(error.error);
              return of(false);
            }));
    }
    SocialLogin(data) {
      this.errors = [];

        return this.http.post<any>(`${environment.ApiBaseUrl}/api/account/sociallogin`,  data)
        .pipe(
            tap(tokens => this.doLoginUser(data.email, tokens)),
            mapTo(true),
            catchError(error => {
             this.SendMessage(error);
             // console.log(error);
             // alert(error.error);
              return of(false);
            }));
    }
    private SendMessage(err){
      try {
        //this.successfulSave = false;
        if (err.status === 400) {
          // handle validation error
          console.log(err);

          let strError="";
          let validationErrorDictionary = err.error;//JSON.parse(err.text());
          for (var fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
              strError=validationErrorDictionary[fieldName]+'\r\n';
              this.errors.push(validationErrorDictionary[fieldName]);
            }
          }
          this._toastrService.error(strError,"Error al iniciar sesiòn")
         // console.log(this.errors);
        } else {
          this.errors.push("something went wrong!");
        }
      } catch (error) {
        console.log(error);
      }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
        localStorage.removeItem(this.ACCESS_MODULE);
       // this.currentUserSubject.next(null);
    }


      isLoggedIn() {
        return !!this.getJwtToken();
      }
      isAccessModuleIn() {
        return !!this.getAccesModuel();
      }

      refreshToken() {
        return this.http.post<any>(`${environment.ApiLoginBaseUrl}/api/account/refreshtoken`, {
          'refresh_token': this.getJwtToken()
        }).pipe(tap((tokens: Tokens) => {
          this.storeJwtToken(tokens.accessToken);
        }));
      }

      getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
      }
      getAccesModuel() {
        return localStorage.getItem(this.ACCESS_MODULE);
      }

      private doLoginUser(username: string, tokens: Tokens) {
        this.loggedUser = username;
        this.storeTokens(tokens);
      }

      private doLogoutUser() {
        this.loggedUser = null;
        this.removeTokens();
      }

      private getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
      }

      private storeJwtToken(jwt: string) {
        localStorage.setItem(this.JWT_TOKEN, jwt);
      }
      public getUserInfo(){
        try {
          var token=this.getJwtToken();
          if(token!=null){
                let decoded = jwt_decode(token);
                return decoded;
          }
          return null;
        } catch (error) {
          return null;
        }
      }
      private setAccessModule(){
          try {
              //Administrators
              var token=this.getJwtToken();
              if(token!=null){
                    let decoded = jwt_decode(token);
                    let roles=decoded.role;
                    if(roles.indexOf('Administrators') > -1){
                        localStorage.setItem(this.ACCESS_MODULE, "Si");
                    }
              }
              else{
                localStorage.removeItem(this.ACCESS_MODULE);
                  //console.log(channelArray.indexOf('three') > -1);
              }

          } catch (error) {
            localStorage.removeItem(this.ACCESS_MODULE);
          }
      }

      private storeTokens(tokens: Tokens) {
        localStorage.setItem(this.JWT_TOKEN, tokens.accessToken);
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refressToken);
        this.setAccessModule();
      }

      private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
      }
}
