import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { Observable,Subscription,Subscriber } from 'rxjs';
@Injectable()
export class ExcelService {

  private file:File;
  private arrayBuffer:any;
  private filelist:any;
constructor() { }
public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}
excelToJson(event)
  {
  this.file= event.target.files[0];
  let fileReader = new FileReader();
  fileReader.readAsArrayBuffer(this.file);
  fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary"});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
     // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
       // var arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
            this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});
            //console.log(this.filelist );
          return this.filelist;

  }



}

importFromExcel(ev): Observable<any> {
  let workbook;
  let excelInJSON;

  const fileReader = new FileReader();

  // init read
  fileReader.readAsArrayBuffer((<any>ev.target).files[0]);

  return Observable.create((observer: Subscriber<any[]>): void => {
    // if success
    fileReader.onload = ((ev: ProgressEvent): void => {
      let binary = "";
      let bytes = new Uint8Array((<any>ev.target).result);
      let length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      // Converts the excel data in to json
      workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
      // only first sheet
      excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      observer.next(excelInJSON);
      observer.complete();
    });
    fileReader.onerror = function(event) {
      throw new Error("There was an issue reading the file." + fileReader.error);
  };

   /* fileReader.onerror = function () {
      throw new Error("There was an issue reading the file." + fileReader.error);
   };
   /* fileReader.onerror = (error: FileReaderProgressEvent): void => {
      observer.error(error);
    }*/

  });
}


}
